<template>
  <div>
    <slot />
  </div>
</template>
<script>

export default {
  components: {
  },
  data () {
    return {
      sidebarActive: false
    };
  },
  mounted () { 
  },
  methods: {
    onActivateSidebar () {
      this.sidebarActive = !this.sidebarActive;
    },
  },  
  computed: {
    appVersion() {
      return document.documentElement.dataset.buildTimestampUtc;
    }
  },
};
</script>

<style lang="scss">

@mixin for-phone-only {
  @media (max-width: 599px) { @content; }
}
@mixin for-tablet-portrait-up {
  @media (min-width: 600px) { @content; }
}
@mixin for-tablet-landscape-up {
  @media (min-width: 900px) { @content; }
}
@mixin for-desktop-up {
  @media (min-width: 1200px) { @content; }
}
@mixin for-big-desktop-up {
  @media (min-width: 1800px) { @content; }
}
.menu-bar {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 70px;
  width: 100%;
  z-index: 99;
  background: $color1;
  backdrop-filter: blur(8px);
}
.menu-link {
  border-radius: 10px;
  padding: 10px;
  color: #fff;
  background: rgba(255,255,255,0);
  margin: 10px;
  transition: 120ms;
  .fa,.fal,.far {
    font-size: 20px;
    margin-right: 10px  
  }
  &:hover {
    background: rgba(255,255,255,0.1);
  }
  &.active {
    background: rgba(255,255,255,0.2);
  }
}

</style>
