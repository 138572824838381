<template>
  <div class="full-page-layout">
    <slot />
  </div>
</template>
<style lang="scss" scoped>
.full-page-layout{
  background: linear-gradient(30deg, rgba(9,150,200,1) 0%,  rgba(57,204,146,1)100%);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
}
</style>
