<template>
  <div class="day-entry" :class="{'checked' : task.is_complete == 1}" v-if="task">
    <div class="task-contents">
      <div class="task-check">
        <div class="day-check" @click="this.taskComplete()"><i class="fa fa-check"></i></div>
      </div>
      <div class="task-info"  @click="editTask">        
        <div class="task-collection" v-if="task.collection">
          <div class="collection-tag" :style="[task.collection.color ? {'background' : task.collection.color, 'color' : '#fff'} : {}]"></div>
          <div>{{ task.collection.name  }}</div>
        </div>   
        <div>
          <div class="task-time time-tag" v-if="task.time_start">
            {{ task.time_start }}<span v-if="task.time_end"> - {{ task.time_end }}</span>
          </div>
          <div class="task-time time-tag metric-tag" v-if="task.time_total && task.time_total.minutes > 0">
          {{ task.time_total.label }}
          </div>
          <div class="day-title">{{ task.title }}</div>
          <div class="day-description">{{ task.summary }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Task',
  props: {
    task: Object,
    isMobile: Boolean,
  },
  data () {
    return {
      taskData: null,
    };
  },
  methods: {
    editTask() {
      this.emitter.emit("edit-task", this.task.uid);
    },
    taskComplete() {
      this.taskData = this.task;
      var is_complete = this.task.is_complete;
      is_complete = !is_complete
      this.taskData.is_complete = is_complete;
      this.$http
        .post(process.env.VUE_APP_ROOT_API + "/api/task/complete",
          this.taskData,
          {  
            headers: {'Content-type': 'application/json',}
          })
        .then((response) => {
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
  },
  mounted() {
    
  },
}
</script>

<style lang="scss" scoped>
.task-contents {
  position: relative;
  width: 100%;
  padding-left: 55px;
  z-index: 2;
  display: flex;
  padding-top: 0px;
  padding-right: 20px;
  min-height: 50px;
  justify-content: left;
  align-items: center;
}
.task-info {  
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
}
.day-entry {
  margin: 3px 7px;
  padding: 2px;
  position: relative;
  background: rgba(255,255,255,1);
  font-size: 14px;
  border-radius: 7px;
  transition: background-color 80ms;
  cursor: pointer;
  &:hover {
    background: rgba(255,255,255,1);
  }
  &.checked {
    background: #F7F7F9;
    opacity: 0.8;
    .day-title {
      text-decoration: line-through;
    }
  }
  overflow: hidden;
}
.checked {  
  .day-check {
    background: #D9DADE;
    display: flex;
    justify-content: center;
    align-items: center;
    .fa-check {
      color: #8F95AB;
      opacity: 1;
      font-size: 16px;
    }
  }
  .day-tag {
    opacity: 0.9;
  }
  .day-title {
    opacity: 0.9;
  }
  .task-time {
    opacity: 0.9;
  }
  .day-description {
    opacity: 0.9;
  }
}
.day-check {
  position: absolute;
  left: 15px;
  top: 10px;
  background: #F6F6F9;
  border: none;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  .fa-check {
      opacity: 0;
  }
}
.list-group {
  overflow-y: scroll;
  height: calc(100% - 40px);
}
.day-tag {
  display: inline-block;
  padding: 2px 5px;
  font-size: 13px;
  font-weight: 500;
  margin-right: 0px;
  border-radius: 6px;
  background: transparent;
  margin-bottom: 2px;
  //transition: 300ms;
  .fa-circle {
    position: relative;
    top: -1px;
    margin-left: 3px;
    font-size: 8px;
  }
}
.day-title {
  display: inline;
  color: #878787;
  font-weight: 400;
  line-height: 10px !important;
  font-size: 13px;
  //transition: 200ms;
}
.day-description {
  display: block;
  font-size: 12px;
  color: #aaa;
  margin-top: 2px;
  //transition: 200ms;
}
.day-header {
  position: relative;
  padding: 10px;
  text-align: center;
}
.day-name {
  font-size: 15px;
  font-weight: 600;
}
.day-date {
  position: absolute;
  right: 20px;
  top: 12px;
  font-size: 12px;
  font-weight: 500;
}
.time-tag {
  margin-right: 5px;
  font-size: 12px;
  font-weight: 400;
  color: #C0C0C0;
}

.time-total-tag {
  display: inline-block;
  margin-right: 5px;
  border-radius: 16px;
  font-weight: 400;
  color: #999;
  background: rgba(0,0,0,0.06);
  border: 2px solid transparent;
  padding: 4px;
  border-radius: 3px;
}
.task-tools {
  position: absolute;
  display: flex;
  justify-content: right;
  align-items: center;
  height: 100%;
  right: -10px;
  transition: 220ms;
  width: 60%;
  top: 0px;
  opacity: 0;
}
.task-tool {
  z-index: 99;
  cursor: pointer;
  color: #777;
  margin-right: 10px;
  background: rgba(0,0,0,0.03);
  width: 30px;
  height: 30px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    color: #111;
    background: rgba(0,0,0,0.07);
  }
}
.day-entry:hover {
  .task-tool {
    cursor: pointer;
    margin-right: 10px;
  }
  .task-tools {
    right: 0px;
    opacity: 1;
    //transition: 220ms;
  }
}
.task-time {
  display: inline-block;
}
.task-collection {
  margin-bottom: -3px;
  padding-top: 4px;
  font-size: 12px;
  font-weight: 500; 
  color: #777;
}
.collection-tag {
  position: absolute;
  top: 8px;
  left: 0px;
  height: 35px;
  width: 7px;
  font-size: 9px; 
  font-weight: 500; 
  border-radius: 200px; 
}
.font-white {
  color: #fff;
}
</style>