<template>
  <div class="checklist-item" :class="{'checked':checked == 1}" :key="item.uid">
    <div class="check-box" @click="toggleCheck">
      <i class="fa fa-check" v-if="checked==1"></i>
    </div>
    <div class="check-text">
      <div v-if="!isEditing" @dblclick="isEditing = !isEditing" style="margin-left: 10px; width: 100%;">{{ text }}</div>
      <Input v-model="text" style="width: 100%" ref="editBox" v-if="isEditing" @keyup.enter="saveCheck(true)"/>
    </div>
    <div class="edit-box" v-if="!isEditing && checked!=1" @click="isEditing = !isEditing">
      <i class="fal fa-pencil"></i>
    </div>
    <div class="edit-box" v-if="!isEditing && checked!=1" @click="deleteCheck()">
      <i class="far fa-trash-alt"></i>
    </div>

    <div class="edit-box" v-if="isEditing && checked!=1" @click="saveCheck()">
      <i class="fa fa-check"></i>
    </div>
    <div class="edit-box" v-if="isEditing && checked!=1" @click="cancelCheck">
      <i class="fa fa-xmark"></i>
    </div>
  </div>
</template>

<script>
export default {
  name: "CheckListItem",
  components: { 
  },
  data(){ 
    return {
      checked: false,
      isEditing: this.edit,
      text: this.item.title,
    }
  },
  props: {
    item: Object,
    task: String,
    edit: Boolean,
  },
  mounted() {
    if (this.item) {
      if (this.item.is_checked) {
        this.checked = this.item.is_checked;
      }
    }
    if (this.edit) {
      this.$nextTick(() => {
        this.$refs.editBox.$el.focus()
      })
    }
  },
  watch: {
    item() {
      this.isEditing = false;
    }
  },
  methods: {
    toggleCheck() {
      let post = {}
      this.checked = !this.checked;
      post.checked = this.checked;
      this.$http
      .post(process.env.VUE_APP_ROOT_API + "/api/task/"+this.task+"/todo/check/"+this.item.uid,
        post,
        {
          headers: { 'Content-type': 'application/json', }
        })
      .then((response) => {
        this.$parent.$parent.getTask();
      })
      .catch((e) => {
        console.log(e);
      });
    },
    cancelCheck() {
      this.$parent.newItem = false;
      this.$parent.$parent.newItem = false;
      this.isEditing = false;
    },
    saveCheck(keepOpen=false) {
      if (!this.task) {
        var task = {};
        task.title = this.text;

        if (this.item && this.item.uid) {
          task.uid = this.item.uid;
          var list = this.$parent.$parent.checklist;
          for (const obj of list) {
            if (obj.uid === task.uid) {
              obj.title = task.title;
              break;
            }
          }
        } else {
          task.uid = Date.now();
          this.$parent.$parent.checklist.push(task);
        }
        this.text = '';
        if (!keepOpen) {
          this.cancelCheck();
        }

        return false;
      }
      let post = {}
      post.list = this.list;
      post.check_text = this.text;
      var url = '';
      if (this.item && this.item.uid) {
        url = '/'+this.item.uid;
      }
      this.$http
      .post(process.env.VUE_APP_ROOT_API + "/api/task/"+this.task+"/todo"+url,
        post,
        {
          headers: { 'Content-type': 'application/json', }
        })
      .then(() => {
        this.$parent.$parent.getTask();
        this.text = '';
        if (!keepOpen) {
          this.cancelCheck();
        }
      })
      .catch((e) => {
        console.log(e);
      });
    },
    deleteCheck() {

      if (!this.task) {
        var list = this.$parent.$parent.checklist;
        console.log(list);
        const index = list.indexOf(this.item);
        if (index > -1) {
          list.splice(index, 1);
        }
        return false;
      }

      let post = {}
      post.list = this.list;
      post.uid = this.item.uid;
      this.$http
      .delete(process.env.VUE_APP_ROOT_API + "/api/task/"+this.task+"/todo/"+this.item.uid,
        post,
        {
          headers: { 'Content-type': 'application/json', }
        })
      .then(() => {
        this.$parent.$parent.getTask();
      })
      .catch((e) => {
        console.log(e);
      });
    }
  },
  computed: {
    
  },
};
</script>
<style lang="scss" scoped>
.checklist-item {
  display: grid;
  gap: 0px;
  grid-template-columns: 28px 1fr 40px 40px;
  padding: 5px;
  min-height: 50px;
  padding-left: 15px;
  margin-left: -15px;
  margin-right: -15px;
  border-radius: 8px;
  padding-right: 15px;
  align-items: center;
  .check-box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    border-radius: 6px;
    background: #F3F3F3;
    cursor: pointer;
    transition: padding-right 300ms, background-color 50ms;
    color: #fff;
    font-size: 16px;
    padding-right: 20px;
  }
  .edit-box {
    opacity: 0;    
    @include for-phone-only() {
      opacity: 1;   
    }
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 100px;
    background: transparent;
    cursor: pointer;
    transition: 80ms;
    right: -10px;
    color: #bbb;
    font-size: 13px;
    &:hover {
      background: rgba(0,0,0,0.03);
      color: #333;
    }
  }
  &:hover {
    background-color: #fafafa;
    .edit-box {
      opacity: 1;
    }
  }
  .check-text {
    display: flex;
    justify-content: left;
    align-items: center;
    min-height: 30px;
    font-size: 14px;
    color: #7E7E7E;
    font-weight: 500;
  }
}
.checked .check-text {
  color: #bbb;
  text-decoration: line-through;
}
.checked .check-box {
  background: #18BECF;
  padding-right: 0px;
}
</style>