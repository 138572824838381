import { createApp } from 'vue'
import { createHead } from '@vueuse/head';
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import mitt from 'mitt';

// import overwriting css
import '@/assets/scss/layout.scss';
import '@/assets/scss/theme.scss';
import '@/assets/scss/animations.scss';
import '@/assets/css/thin.min.css'
import '@/assets/css/solid.min.css'
import '@/assets/css/regular.min.css'
import '@/assets/css/light.min.css'
import '@/assets/css/fontawesome.min.css'

// import PrimeVue styling
import 'primevue/resources/primevue.min.css'; // core css
import 'primeicons/primeicons.css'; // icons

const emitter = mitt();
const app = createApp(App);
const head = createHead();

import PrimeVue from 'primevue/config';

import ToastService from 'primevue/toastservice';
import Toast from 'primevue/toast';
import Checkbox from 'primevue/checkbox';
import Input from 'primevue/inputtext';
import Textarea from 'primevue/textarea';
import Button from 'primevue/button';
import Task from '@/components/Task.vue';
import Dropdown from 'primevue/dropdown';
import SelectButton from 'primevue/selectbutton';
import Calendar from 'primevue/calendar';
import Datepicker from '@vuepic/vue-datepicker';
import Ripple from 'primevue/ripple';
app.directive('ripple', Ripple);
import drag from "v-drag"
import '@vuepic/vue-datepicker/dist/main.css'

app.component('Calendar', Calendar);
app.component('Datepicker', Datepicker);
app.component('SelectButton', SelectButton);
app.component('Dropdown', Dropdown);
app.component('Task', Task);
app.component('Toast', Toast);
app.component('Input', Input);
app.component('TextArea', Textarea);
app.component('Button', Button);
app.component('Checkbox', Checkbox);

// import page layouts
import FullLayout from '@/layouts/FullLayout.vue';
import LoginLayout from '@/layouts/LoginLayout.vue';
import AppLayout from '@/layouts/AppLayout.vue';
app.component('FullLayout', FullLayout);
app.component('LoginLayout', LoginLayout);
app.component('AppLayout', AppLayout);

app.config.globalProperties.emitter = emitter;


import axios from 'axios';
axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token');
axios.interceptors.response.use(
	response => {
		if (response.status === 200 || response.status === 201) {
			if (response.headers['refresh-token']) {
				var token = response.headers['refresh-token']
				localStorage.setItem('token', token);
				axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
			}
			return Promise.resolve(response);
		} else {
			return Promise.reject(response);
		}
	},
	error => {
		if (error.response.status) {
			var errorMsg = ''
			switch (error.response.status) {
				case 400:
					break;
				case 401:
					errorMsg = error.response.data.description;
					emitter.emit("error", '401 Not Authorised');
					router.push({ name: 'Login' });
					break
				case 500:
				case 405:
					errorMsg = error.response.data.error.description;
					break;

			}
			return Promise.reject(error.response);
		}
	}
);

import Pusher from "pusher-js";
var pusher = new Pusher("9464b805e6bdb6c81c88", { cluster: "eu" });
app.config.globalProperties.$pusher = pusher

/*
import GAuth from 'vue3-google-oauth2'
const gAuthOptions = { clientId: '161584449067-nb3oo6o10n8msod4869ltatgel5pet7t.apps.googleusercontent.com', scope: 'email', prompt: 'consent', fetch_basic_profile: false }
app.use(GAuth, gAuthOptions);
*/

app.config.globalProperties.$http = axios;
app.use(router).use(PrimeVue, { ripple: true }).use(head).use(ToastService).use(drag);
app.mount('#app');
