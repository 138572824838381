<template>
  <div class="week-day" :class="{'today':isToday}">
    <div class="day-header">
      <div class="day-add" @click="newTask"><i class="fa fa-plus"></i></div>
      <div class="day-name">{{ dayName }}</div>
      <div class="day-date">{{ dayDate }}</div>
    </div>
    <div class="day-footer" v-if="dayTimes">
      <div v-if="dayTimes.total && dayTimes.total.minutes > 0">
        <div style="display: flex; align-items: center; justify-content: center; border-radius: 100%;width: 35px; height: 35px; color: #666; background: #eee; font-weight: 500; font-size: 11px;">
          {{ dayTimes.total.time.label }}
        </div>
      </div>
      <div v-if="dayTimes.collections" style="position: absolute; right: 5px; top: 5px;">
        <div v-for="(collection, id) in dayTimes.collections" :key="id" style="display: inline-block; margin-left: 5px;">
          <div style="display: flex; align-items: center; justify-content: center; border-radius: 40px;width: 35px; height: 17px; color: #fff; font-weight: 500; font-size: 11px;" :style="'background: '+collection.color">
            {{ collection.time.label }}
          </div>
        </div>
      </div>
    </div>
    <Container v-if="taskEntries"
      orientation="vertical"
      group-name="col"
      @drop="drop"
      :animation-duration="220"
      drag-handle-selector=".dragHandle"
      :drop-placeholder="{ 
        className: 'drop-preview', 
        animationDuration: '60', 
        showOnTop: true 
      }"
      drag-handle-selectord=".drag-handle"
      drag-class="card-ghost"
      drop-class="card-ghost-drop"
      :get-child-payload="getChildPayload"
      style="height: calc(100% - 83px); overflow-y: auto;"
      @scroll="isScrolling()"
      >        
      <Draggable v-for="(tasks, id) in taskEntries" class="day-tasks" :key="id">
        <Task :task="tasks" :class="{ 'dragHandle' : !isMobile }" :isMobile="isMobile"/>
      </Draggable>
    </Container>
  </div>
</template>
<script>
import { Container, Draggable } from "vue3-smooth-dnd";
import { parseISO,format } from 'date-fns'
export default {
  name: 'Day',
  components: { Container, Draggable },
  props: {
    tasks: Object,
    times: Object,
    dayOfWeek: Number,
    firstDate: String,
    isMobile: Boolean,
  },
  data () {
    return {
      day: '',
      isToday: false,
      dayName: '',
      dayDate: '',
      todayDate: '',
      drag: false,
      taskEntries: [],
      dayTimes: [],
    }
  },
  methods: {
    isScrolling() {
      this.emitter.emit("scrolling", true);
    },
    newTask() {
      this.emitter.emit("new-task", this.todayDate);
    },
    applyDrag (arr, dragResult) {
      const { removedIndex, addedIndex, payload } = dragResult
      if (removedIndex === null && addedIndex === null) return arr

      const result = [...arr]
      let itemToAdd = payload

      if (removedIndex !== null) {
        itemToAdd = result.splice(removedIndex, 1)[0]
      }

      if (addedIndex !== null) {
        result.splice(addedIndex, 0, itemToAdd)
        if (removedIndex == null) {
          this.$http
          .get(process.env.VUE_APP_ROOT_API + "/api/task/move", {
            params: {
              uid: itemToAdd.uid,
              date: this.todayDate,
            }
          })
          .then((response) => {
            this.$parent.$parent.$parent.getTasks();
            this.setOrder(this.todayDate,result)
          })
          .catch((e) => {
            this.errors.push(e);
          });
        } else {
            this.setOrder(this.todayDate,result)
        }
      }

      return result
    },
    setOrder(date,tasks) {
      var arr = {}
      arr.tasks = tasks

      this.$http
        .post(process.env.VUE_APP_ROOT_API + "/api/task/sort",
          arr,
          {  
            headers: {'Content-type': 'application/json',}
          })
        .then((response) => {
        })
        .catch((e) => {
          console.log(e);
        });
    },
    drop(dropResult) {
      var entries = this.applyDrag(this.taskEntries, dropResult);
      this.taskEntries = entries;
    },    
    getChildPayload (index) {
      return this.taskEntries[index];
    },
    getDay() {
      var date = parseISO(this.firstDate)
      date.setDate(date.getDate() + this.dayOfWeek)
      date.setHours(0, 0, 0, 0)

      this.dayName = format(date, 'EEEE')
      this.dayDate = format(date, 'dd MMM')
      this.todayDate = format(date, 'yyyy-MM-dd')

      var todayDate = this.todayDate;

      date = format(date, 'yyyy-MM-dd EE');
      this.day = date;

      var today = new Date();
      today = format(today, 'yyyy-MM-dd EE');

      if (today == this.day) {
        this.isToday = true;
      } else {
        this.isToday = false;
      }      
      var taskArray = Object.values(this.tasks);
      this.taskEntries = taskArray.filter(task => task.task_date === this.todayDate);

      this.dayTimes = this.times[todayDate];
    }
  },
  mounted() {
    this.getDay();
  },
  watch: {
    firstDate: function () {
      this.getDay();
    },
    tasks: function () {
      this.getDay();
    }
  },
  computed: {
  }
}
</script>

<style lang="scss">
.card-ghost {
  opacity: 1;
  border-radius: 6px;
  transform: rotate(-2deg);
  box-shadow: -5px 7px 7px -4px rgba(0, 0, 0, 0.09);
  border-bottom: 0px !important;
}
.day-tasks {  
  &:last-child {
    .day-entry {
      border-bottom: 0px !important;
    }
  }
}
.card-ghost-drop {
  opacity: 1;
}
.drop-preview {
  background: #fff;
  border: 2px dotted #aaa;
  opacity: 0.3;
  border-radius: 8px;
  margin:4px;
}
.list-group {
  overflow-y: scroll;
  height: calc(100% - 40px);
}
.day-tag {
  display: inline-block;
  padding: 2px 10px;
  font-size: 12px;
  font-weight: 500;
  margin-right: 5px;
  background: #222;
  border-radius: 100px;
  margin-bottom: 2px;
  color: #fff;

}
.day-description {
  display: inline;
}
.week-day {
  position: relative;
  background: #fff;
  &.today {
    box-shadow: 0px 0px 9px 1px rgba(0, 0, 0, 0.05);
    .day-name {
      color: #36BED2 !important;
    }
  }
  border-radius: 12px;
  width: 100%;
  max-height: 100%;
  @include for-tablet-portrait-up() { 
    height: calc(50vh - 55px);
    left: auto !important;
  }
  @include for-tablet-landscape-up() { 
    height: calc(50vh - 50px);
    
  }
  @include for-phone-only() { 
    width: calc(100% - 20px);
    left: 10px;
    height: calc(100vh - 90px);
    top: 5px;
    z-index: 1;
  }

  
  .day-add {
    position: absolute;
    top: 5px;
    left: 5px;
    z-index: 99;
    cursor: pointer;
    color: #777;
    margin-right: 10px;
    transition: 220ms;
    background: rgba(0,0,0,0.03);
    width: 30px;
    height: 30px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.05;
    &:hover {
      color: #111;
      background: rgba(0,0,0,0.07);
    }
  }
  &:hover {
    .day-add {
      opacity: 1;
    }
  }
  @include for-phone-only() {
    .day-add {
      opacity: 1;
    }
  }

}
.smooth-dnd-disable-touch-action * {
  touch-action: auto !important;
  -ms-touch-action: auto;
}
.day-footer {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width:100%;
  padding: 5px;
  border-top: 1px solid #F3F4F7;
  border-radius: 0px 0px 10px 10px;
  background: #fff; 
  font-size: 12px;
  z-index: 9;
}
.day-header {
  position: relative;
  padding: 10px;
  text-align: center;
}
.day-name {
  font-size: 15px;
  font-weight: 500;
}
.day-date {
  position: absolute;
  right: 20px;
  top: 12px;
  font-size: 12px;
  font-weight: 500;
}
</style>