<template>
  <div>
    <div class="back-drop" @click="closePopup"></div>
    <div class="new-task" v-drag="{ handle: '#handle' }">
      <div class="scrollable">
        <div style="height: 50px; padding: 5px; position: absolute; top: 10px">
          <div style="display: inline-block; margin-right: 10px; font-size: 26px; color: #eee; margin-left: -20px" class="move-handle" id="handle"><i class="far fa-grip-dots-vertical"></i></div>
          <div style="display: inline-block; margin-right: 20px; font-size: 26px; color: #CFCFCF;"><i class="far fa-calendar"></i></div>
          <div style="display: inline-block; position: relative; top: -3px; width: 200px">
            <Datepicker v-model="task.task_date"
            :auto-position="false"
            position="right"
            :clearable="false"
            format="yyyy-MM-dd"
            previewFormat="yyyy-mm"
            autoApply 
            utc="preserve" 
            hide-input-icon 
            :enable-time-picker="false" 
            input-class-name="datepicker"
            />
          </div>
        </div>
        <i class="far fa-times" style="cursor: pointer; font-size: 30px; position: absolute; right: 25px; top: 18px;" @click="closePopup"></i>
        
        <div class="task-input" style="margin-top: 35px;">
          <Input v-model="task.title" placeholder="Task title"/>
        </div>
        <div class="task-input">
          <Dropdown v-model="task.collection" :options="collections" optionLabel="name" filter  placeholder="Select a collection" style="width: 100%;">
            <template #value="slotProps">
                <div v-if="slotProps.value" class="flex align-items-center">
                    <div style="position: absolute; top: 10px; left: 10px;height: 25px; width: 8px; border-radius: 10px;" :style="'background: '+slotProps.value.color"></div>
                    <div style="margin-left: 15px;">{{ slotProps.value.name }}</div>
                </div>
                <span v-else>
                    {{ slotProps.placeholder }}
                </span>
            </template>
            <template #option="slotProps">
                <div style="padding: 4px;">
                    <div style="position: absolute; top: 10px; left: 10px;height: 17px; width: 8px; border-radius: 10px;" :style="'background: '+slotProps.option.color"></div>
                    <div style="margin-left: 10px; color: #666; font-size: 14px;">{{ slotProps.option.name }}</div>
                </div>
            </template>
          </Dropdown>
        </div>
        <div class="task-input">
          <Checkbox v-model="task.rollover" :value="1" :binary="true" id="rollover"/> <label for="rollover">Reminder</label>
        </div>
        <div class="time-grid" v-if="showTime">
          <div class="task-input">
            <div class="label">Time Start</div>
            <Datepicker v-model="time_start" timePicker minutesIncrement="5" hide-input-icon :startTime="{minutes: 0}" autoApply input-class-name="timepicker" />
          </div>
          <div class="task-input">
            <div class="label">Time End</div>
            <Datepicker v-model="time_end" timePicker minutesIncrement="5" hide-input-icon :startTime="{minutes: 0}" autoApply input-class-name="timepicker" />
          </div>
          <div class="task-input">
            <div class="label">Hours</div>
            <Dropdown v-model="task.time_total" showClear  :options="times" optionLabel="label" optionValue="minutes" style="width: 100%;" />
          </div>
        </div>      
        <div class="task-input" v-if="showNotes">
          <div class="label">Notes</div>
          <TipTap v-model="task.notes"/>
        </div>     

        <div class="task-input" v-if="showChecklist">
          <div class="label">Checklist</div>
          <CheckList :list="task.list" :task="task.uid" :new="newCheck"/>
        </div>  

        <div style="margin-top: 20px;">
          <div v-if="!showTime" class="show-tab" @click="showTime = !showTime">Add time</div>
          <div v-if="!showChecklist" class="show-tab" @click="showChecklist = !showChecklist; newCheck = true;">Add to-do</div>
          <div v-if="!showNotes" class="show-tab" @click="showNotes = !showNotes">Add notes</div>
        </div>

      </div>

      <div class="save-footer">
        <Button @click="saveTask" class="save-button">Save</Button>
        <Button @click="deleteTask" class="delete-button" v-if="this.uid">Delete</Button>
      </div>
    </div>
  </div>
</template>
<script>
import { format } from 'date-fns'
import CheckList from '@/components/CheckList.vue'
import TipTap from '@/components/TipTap.vue'

export default {
  name: 'NewTask',
  components: { 
    CheckList,  
    TipTap,
  },
  props: {
    uid: String,
    taskDate: String,
  },
  data () {
    return {
      task: {},
      checklist: [],
      showTime: false,
      showNotes: false,
      showChecklist: false,
      newCheck: false,
      collections: null, 
      time_start: null,
      time_end: null,
      time_calc: null,
			times: [
				{label: '30m', minutes: '30'},
				{label: '1h', minutes: '60'},
				{label: '2h', minutes: '120'},
				{label: '3h', minutes: '180'},
				{label: '4h', minutes: '240'},
			]
    }
  },
  methods: {
    format() {
      const weekDays = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
      const monthNames = ["January","February","March","April","May","June","July","August","September","October","November","December"];
      const date = new Date();
      const day = date.getDate();
      const dayOfWeek = date.getDay();
      const month = date.getMonth();
      const year = date.getFullYear();

      return weekDays[dayOfWeek]+', '+day+' '+monthNames[month]+' '+year;
    },
    closePopup() {
      window.location.hash = '';
      this.$emit('closed');
    },
    getCollections() {
      this.$http
        .get(process.env.VUE_APP_ROOT_API + "/api/collections", {})
        .then((response) => {
          this.collections = response.data.data;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    getTimeCalc() {
      if (this.time_start && this.time_end) {        
        this.times = [
          {label: '15m', minutes: '15'},
          {label: '30m', minutes: '30'},
          {label: '1h', minutes: '60'},
        ]; 
        if (this.task && this.task.time_calc) {
          if (this.task.time_calc.minutes && this.task.time_calc.minutes != 15 && this.task.time_calc.minutes != 30 && this.task.time_calc.minutes != 60) { 
            this.times.push(this.task.time_calc); 
          }
        }

        var time_start = this.time_start.hours + ':' + this.time_start.minutes;
        var time_end = this.time_end.hours + ':' + this.time_end.minutes;
        var timeStart = new Date("01/01/2000 " + time_start);
        var timeEnd = new Date("01/01/2000 " + time_end);

        var time_calc = (timeEnd - timeStart)  / 60 /  1000;

        if (time_calc && time_calc != 15 && time_calc != 30 && time_calc != 60) { 
          if (this.task && this.task.time_calc && time_calc == this.task.time_calc.minutes) {
            //do nothing
          } else {
            var hours = (time_calc / 60);
            var rhours = Math.floor(hours);
            var minutes = (hours - rhours) * 60;
            var rminutes = Math.round(minutes);
            var label = rhours + "h" + rminutes;
            if (!rhours) { label = rminutes+'m'; }
            this.times.push({label: label, minutes: time_calc}); 
          }
        }
      }
    },
    getTask(keepOpen=false) {
      var uid = this.uid;
      this.$http
        .get(process.env.VUE_APP_ROOT_API + "/api/task/"+uid)
        .then((response) => {
          this.loading = false;
          this.task = response.data.data;

          if (this.task.note) {
            this.showNotes = true;
            this.task.notes = {};
            this.task.notes.json = this.task.note_json;
            this.task.notes.text = this.task.note;
          } else {
            this.task.notes = {};
          }

          if (this.task.list && this.task.list.todos > 0) {
            this.showChecklist = true;
          }

          if (this.task.rollover == 1) { this.task.rollover = true; }
          else { this.task.rollover = false; }

          this.task.collection_uid = this.task.collection.uid;
          if (this.task.time_calc && this.task.time_calc.minutes && this.task.time_calc.minutes != 15 && this.task.time_calc.minutes != 30 && this.task.time_calc.minutes != 60) { 
            this.times.push(this.task.time_calc); 
          }
          if (this.task.time_start) {
            this.showTime = true;
            this.time_start = {};
            this.time_start.hours = this.task.time_start.substr(0,2);
            this.time_start.minutes = this.task.time_start.substr(3,2);
          }
          if (this.task.time_end) {
            this.showTime = true;
            this.time_end = {};
            this.time_end.hours = this.task.time_end.substr(0,2);
            this.time_end.minutes = this.task.time_end.substr(3,2);
          }
          if (this.task.time_total && this.task.time_total.minutes > 0) { 
            this.task.time_total = this.task.time_total.minutes;                      
            this.showTime = true;
          } else {
            this.task.time_total = null;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    saveTask() {   
      var url = "/api/task";
      if (this.uid) { url = url + '/' + this.uid; }
      if (this.task.collection) { this.task.collection_uid = this.task.collection.uid; }
      if (this.time_start) { this.task.time_start = this.time_start.hours + ':' + this.time_start.minutes; } else { this.task.time_start = ''; }
      if (this.time_end) { this.task.time_end = this.time_end.hours + ':' + this.time_end.minutes; } else { this.task.time_end = ''; }
      if (this.task.rollover) { this.task.rollover = 1; } else { this.task.rollover = 0;}

      this.task.note_json = this.task.notes.json;
      this.task.note = this.task.notes.text;
      this.task.checklist = this.checklist;

      this.$http
        .post(process.env.VUE_APP_ROOT_API + url,
          this.task,
          {  
            headers: {'Content-type': 'application/json',}
          })
        .then((response) => {
          this.$parent.getTasks();
          window.location.hash = '';
          this.closePopup()
        })
        .catch((e) => {
          console.error(e);
        });
    },
    deleteTask() {   
      var url = "/api/task/" + this.uid;

      this.$http
        .delete(process.env.VUE_APP_ROOT_API + url,
          this.task,
          {  
            headers: {'Content-type': 'application/json',}
          })
        .then((response) => {
          this.$parent.getTasks();
          window.location.hash = '';
          this.closePopup()
        })
        .catch((e) => {
          console.error(e);
        });
    },
  },
  mounted() {
    window.location.hash = 'task';
    var today = new Date();
    today = format(today, 'yyyy-MM-dd');
    if (this.taskDate) { today = this.taskDate;  }
    this.task.task_date = today
    this.task.notes = {};
    this.task.list = {};
    if (this.uid) { this.getTask(); }
    this.getCollections();
  },
  watch: {
    $route(to, from) {
      if (to.hash != '#task') { this.closePopup() }
    },
    time_start(to, from) {
      this.getTimeCalc()
    },
    time_end(to, from) {
      this.getTimeCalc()
    }
  },
  computed: {
  }
}
</script>

<style lang="scss" scoped>
.move-handle {
  @include for-phone-only {
    visibility: hidden;
    display: none;
  }
}
.new-task {
  position: absolute;
  width: calc(100vw - 4px);
  left: 2px;
  top: 3px;
  height: calc(100% - 4px);
  border-radius: 20px;
  background: #fff;
  z-index: 5;
  padding-bottom: 20px;
  @include for-tablet-portrait-up() {
    position: relative;
    width: 500px;
    min-height: 200px;
    height: auto;
    top: 10px;
    max-height: calc(100% - 20px);
  }
  .scrollable {
    max-height: calc(100vh - 80px);
    overflow-y: auto;
    padding: 30px;
    padding-bottom: 30px;
    @include for-tablet-portrait-up() {
      padding-bottom: 80px;
    }
  }
  .save-footer {
    position: absolute;
    width: 100%;
    background: #fff;
    height: 80px;
    display: flex;
    justify-content: left;
    align-items: center;
    border-radius: 0px 0px 20px 20px;
    bottom: 0px;
    border-top: 2px solid #eee;
    padding: 30px;
    margin-top: 2px;
    .save-button, .delete-button {
      padding: 12px 40px;
      margin-right: 10px;
    }
    .delete-button {
      background: transparent;
      border: 2px solid #eee;
      color: #777;
      position: absolute;
      right: 10px;
    }
  }
  input {
    width: 100%;
  }
  .task-input {
    margin-bottom: 10px;
  }
  .label {
    font-size: 12px;
    font-weight: 500;
    color: #BFBFBF;
    margin-bottom: 5px;
    margin-top: 10px;
  }
}
.time-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 110px;
  gap: 10px;
}
.back-drop {  
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: #CFD0D2;
  @include for-tablet-portrait-up() {
    background: rgba(0,0,0,0.1);
  }
  z-index: 2;
}
.show-tab {
  background: #FAFAFA;
  color: #aaa;
  display: inline-block;
  padding: 6px 20px;
  margin-right: 10px;
  border-radius: 8px;
  font-size: 12px;
  font-weight: 500;
}
</style>
<style>
.datepicker {
  border: none !important;
  padding: 0px !important;
  font-family: 'Poppins' !important;
}
.timepicker {
  background: #fff;
  border: 2px solid #EDEDED !important;
  border-radius: 13px !important;
  padding: 11px !important;
  font-family: 'Poppins' !important;
}
</style>