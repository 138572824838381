<template>
  <div class="layout-container" style="overflow: hidden">
    4242 4242 4242 4242
    <div class="checkout-container"></div>

    
  </div>
</template>

<script>
import { inject, toRefs } from "vue";
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
  data(){
    return {
      user: '',
    }
  },
  mounted () { 
    const paddleScript = document.createElement('script');
    paddleScript.onload = this.initPaddle;
    paddleScript.src = 'https://cdn.paddle.com/paddle/paddle.js';
    document.head.appendChild(paddleScript);
  },
  methods: {
    initPaddle() {
      window.Paddle.Environment.set('sandbox');
      window.Paddle.Setup({
        vendor: 12049,
        debug: true, // Set to false in production
        eventCallback: function(eventData) {
          console.log(eventData.event);
        }
      });
      window.Paddle.Checkout.open({
        method: 'inline', // set to `inline`
        product: 50344, // replace with a product ID or plan ID        
        email: "dirkov@gmail.com",
        country: 'ZA',
        allowQuantity: true,
        quantity: 1,
        customData: 'asd',
        disableLogout: true,
        frameTarget: 'checkout-container', // className of your checkout <div>
        frameInitialHeight: 450, // `450` or above
        frameStyle: 'width:100%; min-width:312px; background-color: transparent; border: none;' // `min-width` must be set to `286px` or above with checkout padding off; `312px` with checkout padding on.
      });
    },
    openCheckout() {
      console.log('sf');
    },
    async handleClickSignIn(){
      try {
        /*
        const googleUser = await this.$gAuth.signIn();
        if (!googleUser) {
          return null;
        }
        console.log("googleUser", googleUser);
        this.user = googleUser.getBasicProfile().getEmail();
        console.log("getId", this.user);
        console.log("getBasicProfile", googleUser.getBasicProfile());
        console.log("getAuthResponse", googleUser.getAuthResponse());
        console.log(
          "getAuthResponse",
          this.$gAuth.instance.currentUser.get().getAuthResponse()
        );
        */
        var authCode = await this.$gAuth.getAuthCode()
        var post = {};
        post.authCode = authCode;
        this.$http
        .post(process.env.VUE_APP_ROOT_API,
          post,
          {  
            headers: {'Content-type': 'application/json',}
          })
        .then((response) => {
          console.log(response);
        })
        .catch((e) => {
          this.errors.push(e);
        });

      } catch (error) {
        //on fail do something
        console.error(error);
        return null;
      }
    },
    async handleClickGetAuthCode(){
      try {
        const authCode = await this.$gAuth.getAuthCode();
        console.log("authCode", authCode);
      } catch(error) {
        //on fail do something
        console.error(error);
        return null;
      }
    },
    async handleClickSignOut() {
      try {
        await this.$gAuth.signOut();
        console.log("isAuthorized", this.Vue3GoogleOauth.isAuthorized);
        this.user = "";
      } catch (error) {
        console.error(error);
      }
    },
    handleClickDisconnect() {
      window.location.href = `https://www.google.com/accounts/Logout?continue=https://appengine.google.com/_ah/logout?continue=${window.location.href}`;
    },
  },
  setup(props) {
    const { isSignIn } = toRefs(props);
    const Vue3GoogleOauth = inject("Vue3GoogleOauth");
    const handleClickLogin = () => {};
    return {
      Vue3GoogleOauth,
      handleClickLogin,
      isSignIn,
    };
  },
};
</script>

<style>
button {
  display: inline-block;
  white-space: nowrap;
  cursor: pointer;
  background: #fff;
  border: 1px solid #dcdfe6;
  color: #606266;
  -webkit-appearance: none;
  text-align: center;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  outline: 0;
  margin: 0;
  -webkit-transition: 0.1s;
  transition: 0.1s;
  font-weight: 500;
  padding: 12px 20px;
  font-size: 14px;
  border-radius: 4px;
  margin-right: 1em;
}
button:disabled {
  background: #fff;
  color: #ddd;
  cursor: not-allowed;
}
</style>