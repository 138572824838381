<template>
  <div style="padding: 10px;">
    <div>
      <div class="weekly-stats-grid" style="padding: 10px; font-size: 12px; color: #aaa;">
        <div>Stats</div>
        <div align="right">This Week</div>
        <div align="right">{{ thisMonth }}</div>
      </div>
    </div>
    <div v-if="metrics">
      <div v-if="metrics.collections">
        <div v-for="(collection, id) in metrics.collections" :key="id">
          <div style="padding: 10px; position: relative; margin-bottom: 5px; font-size: 14px; border-bottom: 1px solid #eee;" class="weekly-stats-grid" v-if="collection.metrics.monthly[thisMonth] || collection.metrics.weekly[thisWeek] || collection.details.target.week || collection.details.target.month">
            <div style="font-weight: 500;">
              <div :style="'background: '+collection.details.color" style="position: absolute; top:5px; left: -5px;height: 36px; width: 5px; border-radius: 20px;"></div>
              {{collection.details.name}}
            </div>
            <div align="right">
              <div v-if="collection.metrics.weekly[thisWeek]">{{ collection.metrics.weekly[thisWeek].time.label }}</div>
              <div style="font-size: 12px; color: #aaa;" v-if="collection.details.target.week">{{ collection.details.target.week }}h</div>
            </div>
            <div align="right">
              <div v-if="collection.metrics.monthly[thisMonth]">{{ collection.metrics.monthly[thisMonth].time.label }}</div>
              <div style="font-size: 12px; color: #aaa;" v-if="collection.details.target.month">{{ collection.details.target.month }}h</div>
            </div>
          </div>
        </div>
      </div>
      <div style="padding: 10px; margin-bottom: 5px; border-radius: 40px; font-size: 18px;" class="weekly-stats-grid" v-if="metrics.total">
        <div style="font-weight: 500;">
          <div style="background: #ddd; height: 10px; width: 20px; border-radius: 20px; margin-right: 10px; display: inline-block;"></div>
          Total
        </div>
        <div align="right"><div v-if="metrics.total.weekly[thisWeek]">{{ metrics.total.weekly[thisWeek].time.label }}</div></div>
        <div align="right"><div v-if="metrics.total.monthly[thisMonth]">{{ metrics.total.monthly[thisMonth].time.label }}</div></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'WeekMetrics',
  props: {
    metrics: Object,
    collections: Object,
    weekly: Object,
    monthly: Object,
    thisMonth: String,
    thisWeek: String
  },
  data () {
    return {
      day: '',
      isToday: false,
      dayName: '',
      dayDate: '',
      todayDate: '',
      drag: false,
      taskEntries: [],
      dayTimes: [],
    }
  },
  methods: {
  },
  mounted() {
  },
  watch: {
  },
  computed: {
  }
}
</script>
<style scoped>
  .weekly-stats-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;
  }
</style>