<template>
  <div class="checklist" v-if="list">
    <div class="checklist-progress">
      <div class="progress-bar" :style="'width:'+completed+'%'"></div>
      <div class="progress-percent">{{ completed }}%</div>
    </div>
    <CheckListItem v-for="check in list.list" :key="check.uid" :item="check" :task="task"/>
    <CheckListItem v-for="check in checklist" :key="check.uid" :item="check"/>
    <CheckListItem v-if="newItem" :item="{}" :edit="true" :task="task" />
    <div class="checklist-add" @click="newItem = !newItem">
      <i class="fa fa-plus"></i> Add checklist entry
    </div>
  </div>
</template>

<script>
import CheckListItem from '@/components/CheckListItem.vue'
export default {
  name: "CheckList",
  components: { 
    CheckListItem
  },
  data(){ 
    return {
      completed: null,
      newItem: false,
      checklist: this.$parent.checklist,
    }
  },
  props: {
    list: Object,
    new: Boolean,
    task: String,
  },
  mounted() {
    if (this.list) {
      this.completed = this.list.completed;
      if (this.new) {
        this.newItem = true;
      }
    }
  },
  watch: {
    list() {
      this.completed = this.list.completed;
    }
  },
  methods: {
  },
  computed: {
    
  },
};
</script>

<style lang="scss" scoped>
.checklist {
  margin-bottom: 20px;
  position: relative;
}
.checklist-progress {
  position: absolute;
  top: -15px;
  right: 0px;
  height: 7px;
  width: 80px;
  border-radius: 40px;
  background: #F3F3F3;
  .progress-bar {
    background: #18BECF;
    width: 0%;
    height: 100%;
    border-radius: 40px;
    transition: 700ms;
  }
  .progress-percent {
    font-size: 11px;
    font-weight: 700;
    position: absolute;
    top: -3px;
    right: 85px;
    text-align: right;
    color: #bbb;
  }
}
.checklist-add {
  display: inline-block;
  padding: 8px 20px;
  border-radius: 50px;
  margin-left: 30px;
  gap: 6px;
  grid-template-columns: 28px 1fr;
  background: #F6F8F9;
  margin-top: 15px;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  color: #999;
  font-weight: 500;
  .fa {
    margin-right: 5px;
  }  
}
</style>