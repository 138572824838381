<template>
  <div class="layout-container" style="overflow: hidden; display: flex; justify-content: center;">
    <div class="popup-container" v-if="newTask">
      <NewTask @closed="closePopup()" :uid="editUID" :taskDate="newTaskDate"/>
    </div>

    <div class="nav-menu">
      <div class="board-nav">
        <a @click="lastWeek()" class="button p-ripple" v-ripple><i class="fal fa-angle-left fa-fw"></i></a>
        <a @click="thisWeek()" class="button p-ripple" v-ripple><i class="fal fa-angle-down fa-fw"></i></a>
        <a @click="nextWeek()" class="button p-ripple" v-ripple><i class="fal fa-angle-right fa-fw"></i></a>
        <a @click="openPopup()" class="button p-ripple" v-ripple><i class="fal fa-plus fa-fw"></i></a>
      </div>

    </div>
    
    
    <Container v-if="tasks"
      id="week-board"
      class="week-board"
      drag-handle-selector=".column-drag-handle"
    >      
      <Draggable v-for="n in 7" :key="n">
        <Day :dayOfWeek="n-1" :firstDate="firstDate" :tasks="tasks" :times="times" :class="{'active':n == active }"/>
      </Draggable>
      <div class="week-day">
        <WeekMetrics v-if="metrics" :metrics="metrics" :thisWeek="firstDate" :thisMonth="thisMonth"/>
      </div>  
    </Container>


    
  </div>
</template>

<script>

import { Container, Draggable } from "vue3-smooth-dnd";
import { format,add,parseISO } from 'date-fns'
import Day from '@/components/Day.vue';
import WeekMetrics from '@/components/WeekMetrics.vue';
import NewTask from '@/components/NewTask.vue';
import { computed } from 'vue'
import { useHead } from '@vueuse/head'
import { useMouse,useWindowSize, useSwipe  } from '@vueuse/core'

Math.easeInOutQuad = function (t, b, c, d) {
  
  t /= d / 2;
  if (t < 1) return c / 2 * t * t + b;
  t--;
  return -c / 2 * (t * (t - 2) - 1) + b;
  
};
function scrollTo(element, to, duration) {
  var start = element.scrollLeft,
    change = to - start,
    currentTime = 0,
    increment = 5;

  var animateScroll = function () {
    currentTime += increment;
    var val = Math.easeInOutQuad(currentTime, start, change, duration);
    element.scrollLeft = val;
    if (currentTime < duration) {
      setTimeout(animateScroll, increment);
    }
  };
  animateScroll();
}

export default {
  setup() {
    const { width, height } = useWindowSize();
    return {
      windowWidth: width,
      windowHeight: height,
    };
  },
  name: 'Dashboard',
  components: {
    Day, Draggable, Container,NewTask,WeekMetrics
  },
  data () {
    return {
      today: '',
      firstDate: null,
      thisMonth: null,
      tasks: null,
      times: null,
      weeks: null,
      metrics: null,
      newTask: false,
      newTaskDate: null,
      editUID: null,
      isMobile: false,
      active: null,
      touchX: null,
      slideRight: false,
      buttonClick: false,
      dayNumber: [],
      dayCount: [],
      scrolling: false,
      dragging: false,
      mouseDif: null,
      swipe: null,
      mouse: null,
      window: null,
    }
  },  
  mounted() {
    this.setMeta()
    document.addEventListener("backbutton", this.yourCallBackFunction, false);
    this.swipe = useSwipe(document.body);
    this.isMobile = false;
    this.windowWidth = window.innerWidth;
    if (this.windowWidth <= 599) { this.isMobile = true; }
    var date = new Date();
    this.today = format(date, 'yyyy-MM-dd');
    this.getDate();
    //this.getTasks();
    this.emitter.on("edit-task", uid => {
      this.editUID = uid;
      this.newTaskDate = null;
      this.newTask = true;
    });
    this.emitter.on("scrolling", status => {
      this.scrolling = status;
    });
    this.emitter.on("new-task", date => {
      this.editUID = null;
      this.newTaskDate = date;
      this.newTask = true;
    });

    var channel = this.$pusher.subscribe('1');

    channel.bind("event", (data) => {
      this.getTasks();
    });
  },
  watch: {
    firstDate() {
      if (this.tasks) {
        this.getCount()
      }
    },
    swiping: function () {
      var travel = this.swipe.lengthX
      var travelDistance = this.windowWidth / 4;
      if (!this.swipe.isSwiping) {
        if (travel > travelDistance) {
          this.scrollBoard('forward')
        }
        if (travel < (travelDistance*-1)) {
          this.scrollBoard('back')
        }
      }      
    },
    active(newVar) {
      if (this.tasks) {
        this.$nextTick(() => {
          var windowWidth = this.windowWidth - 0;
          var newScroll = (newVar - 1) * windowWidth;
          scrollTo(document.getElementById('week-board'), newScroll, 120);
        });        
      }
    },
  },
  computed: {
    swiping() {
      if (this.swipe) {
        return this.swipe.isSwiping
      }
      return null
    },
  },
  methods: {
    scrollBoard(direction,num=0) {
      var windowWidth = this.windowWidth - 0;
      var nextScroll = 0;
      if (direction == 'forward') {
        this.active++;
        if (this.active > 8) { this.active = 1; }
        num = this.active;
      }
      if (direction == 'back') {
        this.active--;
        if (this.active < 1) { this.active = 8; }
        num = this.active;
      }
      if (num < 0) {
        num = 0;
      }
      if (num>0) {
        nextScroll = windowWidth * (num-1);
      }
      this.scrollIndex = num;
      if (nextScroll < 0) {
        return false;
      }
      this.boardScroll = nextScroll;
      this.boardX = nextScroll*-1;
      scrollTo(document.getElementById('week-board'),nextScroll, 120);
    },
    getCount() {
      if (this.tasks) {
        var taskArray = Object.values(this.tasks);
        var date = parseISO(this.firstDate)
        var thisDate = format(date, 'yyyy-MM-dd')
        this.dayCount[1] = taskArray.filter(task => task.task_date === thisDate).length;

        var newDate = date.setDate(date.getDate() + 1);
        thisDate = format(newDate, 'yyyy-MM-dd')
        this.dayCount[2] = taskArray.filter(task => task.task_date === thisDate).length;

        newDate = date.setDate(date.getDate() + 1);
        thisDate = format(date, 'yyyy-MM-dd')
        this.dayCount[3] = taskArray.filter(task => task.task_date === thisDate).length;

        newDate = date.setDate(date.getDate() + 1);
        thisDate = format(date, 'yyyy-MM-dd')
        this.dayCount[4] = taskArray.filter(task => task.task_date === thisDate).length;

        newDate = date.setDate(date.getDate() + 1);
        thisDate = format(date, 'yyyy-MM-dd')
        this.dayCount[5] = taskArray.filter(task => task.task_date === thisDate).length;

        newDate = date.setDate(date.getDate() + 1);
        thisDate = format(date, 'yyyy-MM-dd')
        this.dayCount[6] = taskArray.filter(task => task.task_date === thisDate).length;

        newDate = date.setDate(date.getDate() + 1);
        thisDate = format(date, 'yyyy-MM-dd')
        this.dayCount[7] = taskArray.filter(task => task.task_date === thisDate).length;
      }
    },
    openPopup() {
      this.newTask = true;
      this.editTask = null;
      this.newTaskDate = null;
    },
    closePopup() {
      this.newTask = false;
      this.editTask = null;
      this.newTaskDate = null;
    },
    editTask(uid) {
      this.editUID = uid;
      this.newTask = true;
    },
    getTasks() {
      this.$http
        .get(process.env.VUE_APP_ROOT_API + "/api/task?date="+this.today, {})
        .then((response) => {
          this.tasks = response.data.data.tasks;
          this.times = response.data.data.metrics.daily;
          this.getCount();
          this.getMetrics();

          
          this.$nextTick(() => {
            var windowWidth = this.windowWidth - 0;      
            var nextScroll = windowWidth * (this.active-1);
            scrollTo(document.getElementById('week-board'),nextScroll, 15);
          });

        })
        .catch((e) => {
        });
    },
    getMetrics() {
      this.$http
        .get(process.env.VUE_APP_ROOT_API + "/api/task/metrics", {})
        .then((response) => {
          this.metrics = response.data.data;
        })
        .catch((e) => {
        });
    },
    thisWeek() {
      var date = new Date();
      this.today = format(date, 'yyyy-MM-dd');
      this.getDate();
    },
    nextWeek() {
      var date = parseISO(this.today)
      var today = add(date, {weeks: 1});
      today = format(today, 'yyyy-MM-dd');
      this.today = today;
      this.getDate();
      this.active = 1;
    },
    lastWeek() {
      var date = parseISO(this.today)
      var today = add(date, {weeks: -1});
      today = format(today, 'yyyy-MM-dd');
      this.today = today;
      this.getDate();
      this.active = 7;
    },
    getDate() {
      var date = parseISO(this.today)
      var day = date.getDay();
      this.active = day+1;
      this.getTasks();

      var weekStartsOn = 0;
      var diff = (day < weekStartsOn ? 7 : 0) + day - weekStartsOn;
      date.setDate(date.getDate() - diff)
      date.setHours(0, 0, 0, 0)
      date = format(date, 'yyyy-MM-dd');
      this.firstDate = date;
  
      date = parseISO(this.firstDate)
      date = format(date, 'yyyy-MM');
      this.thisMonth = date;

      date = parseISO(this.firstDate)
      this.dayNumber[1] = format(date, 'dd');
      date.setDate(date.getDate() + 1)
      this.dayNumber[2] = format(date, 'dd');
      date.setDate(date.getDate() + 1)
      this.dayNumber[3] = format(date, 'dd');
      date.setDate(date.getDate() + 1)
      this.dayNumber[4] = format(date, 'dd');
      date.setDate(date.getDate() + 1)

      var thisMonth = format(date, 'yyyy-MM');
      this.thisMonth = thisMonth;

      this.dayNumber[5] = format(date, 'dd');
      date.setDate(date.getDate() + 1)
      this.dayNumber[6] = format(date, 'dd');
      date.setDate(date.getDate() + 1)
      this.dayNumber[7] = format(date, 'dd');
    },
    setMeta() {
      useHead({
        title: computed(() => this.today+' | ' + process.env.VUE_APP_NAME),
      })
    },   
  },
}
</script>
<style lang="scss" scoped>
.nav-menu {
  position: fixed;
  top: 0px;
  left: 0px;
  background: $primary;
  width: 100%;
  height: 65px;
  @include for-phone-only() { 
    top: inherit;
    bottom: 0px;
  }
}
.board-nav {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
  
  .button {
    position: relative;
    margin: 5px;
    margin-top: 0px;
    border-radius: 6px;
    width: 40px;
    height: 40px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: #fff;
    background: rgba(255, 255, 255, 0.22);
    padding: 5px 10px;
    font-size: 24px;
    transition: 120ms;
    &:hover {
      background: rgba(255, 255, 255, 0.1);
    }
    user-select: none;
  }
}
.week-options {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #eceef1;
  bottom: 0px;
  left: 10px;
  border-radius: 20px 20px 0px 0px;
  width: calc(100% - 20px);
  height: 70px;
  @include for-desktop-up() {
    position: absolute;
    display: flex;
    justify-content: center;
    background: #eceef1;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 70px;
    padding-left: 27px;
    padding-right: 0px;
    display: block;
    top: 90px;
    left: 0px;
    width: $menu-width;
    height: calc(100% - 70px);
    display: block;
    background: transparent;
  }
}

.week-option {
  display: inline-block;
  position: relative;
  padding: 10px;
  font-size: 24px;
  margin: 3px;
  color: #b5becf;
  border-radius: 20px;
  background: rgba(0,0,0,0.0);
  transition: 120ms;  
  .menu-label {
    visibility: hidden;
    position: absolute;
    font-size: 14px;
    left: 40px;
    top: 5px;
    border-radius: 20px;
    background: rgba(0,0,0,0.2);
    color: #fff;
    padding: 10px 30px;
    z-index: 1;
    transition: 60ms;
    opacity: 0.5;
  }
  &:hover {
    background: #edeef1;
    .menu-label {
      visibility: visible;
    transition: 220ms;
      left: 60px;
      opacity: 1;
    }
  }
  &.active {
    background: #51d9c4;
    color: #fff;
  }
  cursor: pointer;
}
.week-board {
  display: grid;
  position: relative;
  top: 70px;
  left: 0px;
  width: 100%;
  padding: 10px;
  height: calc(100vh - 100px);
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 15px;
  @include for-tablet-portrait-up() { 
    grid-template-columns: 1fr 1fr;
  }
  @include for-tablet-landscape-up() { 
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  @include for-phone-only() { 
    overflow: hidden;
    max-width: auto !important;
    grid-template-columns: 100vw 100vw 100vw 100vw 100vw 100vw 100vw 100vw;
    padding: 0px;
    top: 8px;
    height: calc(100vh - 40px);
    grid-gap: 0px;
  }
}
.popup-container {
  position: fixed;
  display: block;
  @include for-tablet-portrait-up() {
    display: flex;
  }
  justify-content: center;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  z-index: 999;
}
.day-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute; 
  top: 80px; 
  width: 100%; 
  z-index: 1; 
  left: 0px;
}
.day-button {
  position: relative;
  width: 40px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  height: 40px;
  margin-left: 3px;
  margin-right: 3px;
  padding: 5px;
  font-size: 13px;
  border-radius: 15px;
  background: #eceef1;
  color: #c7c9cc;
  transition: 0ms;
  &.active {
    background: #4ed8c3;
    color: #fff;
  }
  .day-button-date {
    position: absolute;
    top: -13px;
    color: #bbb;
    font-size: 9px;
    left: 0px;
    width:100%;
    opacity: 0.6;
  }
  .day-button-count {
    position: absolute;
    top: -5px;
    font-size: 9px;
    left: -5px;
    border-radius: 100%;
    height: 16px;
    width: 16px;
    color: $primary;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.day-pull {
  height: 100%; 
  position: absolute; 
  top: 0px; 
  left: -150%; 
  width: 150%; 
  border-radius: 100%; 
  background: rgba(0,0,0,0.03); 
  z-index: 1;
  transition: 130ms;
}
</style>