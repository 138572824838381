<template>
  <div>
    <form class="login-form" method="post" action="/" align="left" @submit="submit">
          <div class="login-container">
            <div style="width: 100%; margin-bottom: 20px;">
              <div style="margin-bottom: 10px;">Email address</div>
              <Input placeholder="you@company.com" v-model="username" />
            </div>
            <div style="width: 100%;">
              <div style="margin-bottom: 10px;">Password</div>
              <Input type="password" placeholder="shhh..." v-model="password"/>
            </div>
            <div style="width: 100%; margin-top: 20px;">
              <Button type="submit" class="login-button"><i class="fa fa-check"></i>Login</Button>
            </div>
          </div>
        </form>
  </div>
</template>

<script>
export default {
  name: 'Login',
  data () {
    return {
      username: null,
      password: null,
    }
  },
  components: {
    
  },
  methods: { 
    submit : function(e){
      this.$http
      .post(process.env.VUE_APP_ROOT_API + "/auth/login", {
        email: this.username,
        password: this.password,
      })
      .then((response) => {
        var token = response.data.data.jwt;
        console.log(token);
        localStorage.setItem('token',token);
        this.$http.defaults.headers.common['Authorization'] = 'Bearer '+token
        this.$router.push({ name: 'Dashboard' })
      })
      .catch((e) => {
        this.errors.push(e);
      });

      
      e.preventDefault()
    }
  },
  mounted() {   
    //this.getAuth();
  },
}
</script>