<template>  
  <div class="editor-container" v-if="editor">
    <div class="toolbar" v-if="!readonly">
      <div class="tool" @click="editor.chain().focus().toggleBold().run()" :class="{ 'is-active': editor.isActive('bold') }"><i class="fa fa-bold fa-fw"></i></div>
      <div class="tool" @click="editor.chain().focus().toggleItalic().run()" :class="{ 'is-active': editor.isActive('italic') }"><i class="fa fa-italic fa-fw"></i></div>
      <div class="tool" @click="editor.chain().focus().toggleStrike().run()" :class="{ 'is-active': editor.isActive('strike') }"><i class="fa fa-strikethrough fa-fw"></i></div>
      <div class="tool-separator">&nbsp;</div>
      <div class="tool" @click="editor.chain().focus().toggleBulletList().run()" :class="{ 'is-active': editor.isActive('bullet_list') }"><i class="fa fa-list-ul fa-fw"></i></div>
      <div class="tool" @click="editor.chain().focus().toggleOrderedList().run()" :class="{ 'is-active': editor.isActive('ordered_list') }"><i class="fa fa-list-ol fa-fw"></i></div>
      <div class="tool-separator">&nbsp;</div>
      <div class="tool" @click="editor.chain().focus().toggleHeading({ level: 1 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }"><i class="fa fa-h1 fa-fw"></i></div>
      <div class="tool" @click="editor.chain().focus().toggleHeading({ level: 2 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }"><i class="fa fa-h2 fa-fw"></i></div>
      <div class="tool" @click="editor.chain().focus().toggleHeading({ level: 3 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }"><i class="fa fa-h3 fa-fw"></i></div>

    </div>
    <div class="editor" :class="{'readonly': readonly}">
      <editor-content :editor="editor" @keydown.tab.prevent="editor.chain().focus().insertContent('&#009;').run()" />
    </div>
  </div>
</template>

<script>
import { Editor, EditorContent, VueNodeViewRenderer } from '@tiptap/vue-3'
import StarterKit from '@tiptap/starter-kit'

import CodeBlockComponent from './CodeBlockComponent.vue'
import CodeBlockLowlight from '@tiptap/extension-code-block-lowlight'
import { lowlight } from 'lowlight'
import css from 'highlight.js/lib/languages/css'
import js from 'highlight.js/lib/languages/javascript'
import ts from 'highlight.js/lib/languages/typescript'
import html from 'highlight.js/lib/languages/xml'
lowlight.registerLanguage('html', html)
lowlight.registerLanguage('css', css)
lowlight.registerLanguage('js', js)
lowlight.registerLanguage('ts', ts)

import Typography from '@tiptap/extension-typography'
import Link from '@tiptap/extension-link'

import Mention from '@tiptap/extension-mention'
import suggestion from './suggestion'
import hash from './hash'

export default {
  name: "TipTap",
  components: {
    EditorContent,
  },
  data(){ 
    return {
      editor: null,
      emits: ['update:modelValue'],
      content: {},
    }
  },
  props: {
    readonly: Boolean,
    modelValue: Object,
  },
  beforeUnmount() {
    if (this.editor) { this.editor.destroy() }
  },
  mounted() {   
    this.content = this.modelValue;
    if (this.content) {
      this.content.data = this.content.text;
      if (this.content.json) { this.content.data = this.content.json }
      
        this.editor = new Editor({
          content: this.content.data,
          editable: !this.readonly,
          extensions: [
            StarterKit.configure({
              codeBlock: false,
            }),
            Link.configure({
              HTMLAttributes: {
                rel: 'noopener noreferrer',
                target: null,
              },
            }),
            Typography,
            CodeBlockLowlight
            .extend({
              addNodeView() {
                return VueNodeViewRenderer(CodeBlockComponent)
              },
            })
            .configure({ lowlight }),
          ],
        })
    }
    
  },
  methods: {  
    processTags() {
    }
  },
  computed: {    
    json() {
      if (this.editor) {
        return this.editor.getJSON();
      }
      return false;
    },
    text() {
      if (this.editor) {
        return this.editor.getText();
      }
      return false;
    },
    html() {
      if (this.editor) {
        return this.editor.getHTML();
      }
      return false;
    },
  },
  watch: {  
    json() {
      var data = {}
      data.json = this.json
      data.html = this.html
      data.text = this.text
      this.$emit('update:modelValue', data);
    },
    readonly(value) {
      this.editor.setOptions({editable: !value})
    }

  }
};
</script>
<style lang="scss">
.editor-container {
  position: relative;
  border-radius: 7px;
  .toolbar {
    position: relative;
    height: 100%;
    background: #fafafa;
    border: 2px solid #eee;
    border-radius: 7px 7px 0px 0px;
    top: 0px;
    padding: 5px;
    .tool {
      color: #555;  
      background: transparent; 
      padding: 7px; 
      border-radius: 4px;
      display: inline-block;
      margin: 2px;
      font-size: 14px;
      transition: 200ms;
      width: 36px;
      height: 36px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      &.is-active {
        background: #eee;
      }
      &:hover {
        background: #eee;
      }
    }
    .tool-separator {
      width: 2px;
      height: 26px;
      margin-left: 5px;
      margin-right: 5px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      background:#eee;
    }
  }
  .editor {
    background: #fff;
    border: 2px solid #eee;
    border-top: 0px;
    padding: 0px;
    border-radius: 0px 0px 7px 7px;
    z-index: 2;
    a {
      color: $primary;
      cursor: pointer;
    }
    &.readonly {
      padding: 0px;
      border: 0px;
    }
  }
}
.ProseMirror {
  padding: 10px 20px !important;
  min-height: 100px;
}
.readonly .ProseMirror {
  padding: 0px !important;
}
.ProseMirror-focused {
  outline: none;
}
.mention {
  background: #eee;
  padding: 3px 8px;
  border-radius: 40px;
}
.hash {
  background: #444;
  color: #fff;
  padding: 3px 8px;
  border-radius: 40px;
}
pre {
    tab-size: 2;
    background: #15213d;
    color: #eee;
    font-family: monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    code {
      color: inherit;
      line-height: 20px;
      background: none;
      font-size: 14px;
    }

    

    .hljs-comment,
    .hljs-quote {
      color: #616161;
    }

    .hljs-variable,
    .hljs-template-variable,
    .hljs-attribute,
    .hljs-tag,
    .hljs-name,
    .hljs-regexp,
    .hljs-link,
    .hljs-name,
    .hljs-selector-id,
    .hljs-selector-class {
      color: #71dde2;
    }

    .hljs-number,
    .hljs-meta,
    .hljs-built_in,
    .hljs-builtin-name,
    .hljs-literal,
    .hljs-type,
    .hljs-params {
      color: #FBBC88;
    }

    .hljs-string,
    .hljs-symbol,
    .hljs-bullet {
      color: #faffc5;
    }

    .hljs-title,
    .hljs-section {
      color: #a5c3ff;
    }

    .hljs-keyword,
    .hljs-selector-tag {
      color: #70CFF8;
    }

    .hljs-emphasis {
      font-style: italic;
    }

    .hljs-strong {
      font-weight: 700;
    }
  }
</style>