<template>
  <div class="layout-container">
    <div v-for="(collection,id) in collections" :key="id">
      {{ collection.name }}
    </div>
  </div>
</template>

<script>
import { inject, toRefs } from "vue";
export default {
  name: "Collections",
  data(){
    return {
      collections: [],
    }
  },
  mounted () { 
    this.getCollections();
  },
  methods: {
    getCollections() {
      this.$http
        .get(process.env.VUE_APP_ROOT_API + "/api/collections", {})
        .then((response) => {
          this.collections = response.data.data;
        })
        .catch((e) => {
        });
    },
  },
};
</script>

<style>

</style>