import { VueRenderer } from '@tiptap/vue-3'
import tippy from 'tippy.js'
import MentionList from './MentionList.vue'
import { PluginKey } from '@tiptap/pm/state'
import axios from 'axios';

let users = [];
const sendGetRequest = async () => {
  try {
    const resp = await axios.get(process.env.VUE_APP_ROOT_API + "/api/user", {});
    users = resp.data.data;
    return users;
  } catch (err) {
    console.error(err);
  }
};  

export default {

  char: '@',

  items: ({ query }) => {
    return users.filter(item => item.username.toLowerCase().startsWith(query.toLowerCase())).slice(0, 5)    
  },
  pluginKey: new PluginKey('MentionKey'),
  render: () => {
    let component
    let popup

    users = sendGetRequest()

    return {
      onStart: props => {
        component = new VueRenderer(MentionList, {
          props,
          editor: props.editor,
        })

        if (!props.clientRect) {
          return
        }

        popup = tippy('body', {
          getReferenceClientRect: props.clientRect,
          appendTo: () => document.body,
          content: component.element,
          showOnCreate: true,
          interactive: true,
          trigger: 'manual',
          placement: 'bottom-start',
        })
      },

      onUpdate(props) {
        component.updateProps(props)

        if (!props.clientRect) {
          return
        }

        popup[0].setProps({
          getReferenceClientRect: props.clientRect,
        })
      },

      onKeyDown(props) {
        if (props.event.key === 'Escape') {
          popup[0].hide()

          return true
        }

        return component.ref?.onKeyDown(props)
      },

      onExit() {
        popup[0].destroy()
        component.destroy()
      },
    }
  },
}