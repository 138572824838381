import { createRouter, createWebHistory } from 'vue-router'
import Dashboard from '@/views/Dashboard.vue'
import Collections from '@/views/Collections.vue'
import Login from '@/views/Login.vue'
import Test from '@/views/Test.vue'

const ifAuthenticated = (to, from, next) => {
  if (localStorage.getItem('token')) {
    next();
    return;
  }
  router.push({
    name: 'Login',
    params: {
      returnTo: to.path,
      query: to.query,
    },
  });
};

const defaultRoute = 'app';

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    meta: { 
      title: 'Week view', 
      description: 'Listings page' ,
      layout: defaultRoute,
    },
    component: Dashboard,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/collections',
    name: 'Collections',
    meta: { 
      title: 'Collections', 
      description: 'Collections page' ,
      layout: defaultRoute,
    },
    component: Collections,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/login',
    meta: { 
      title: 'Week view', 
      description: 'Listings page', 
      layout: 'login' 
    },
    name: 'Login',
    component: Login,
  },
  {
    path: '/test',
    name: 'Test',
    meta: { title: 'Week view', description: 'Listings page', layout: 'fullpage' },
    component: Test,
    beforeEnter: ifAuthenticated,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
