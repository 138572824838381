<template>
  <component :is="layout">
    <router-view v-slot="{ Component }">
      <component :is="Component" />
    </router-view>
  </component>
  <Toast position="top-right" />
</template>

<script>
import { computed, reactive } from 'vue';
import { useHead } from '@vueuse/head';
import { useRoute } from 'vue-router';

const defaultLayout = 'full';

export default {
  setup () {
    const route = useRoute();
    useHead({
      title: computed(
        () => (route.meta.title ? route.meta.title+' | ' : '') + process.env.VUE_APP_NAME
      ),
      meta: [
        {
          name: 'description',
          content: computed(() => route.meta.description || 'Weekblocks')
        }
      ]
    });
  },
  methods: {
  },
  computed: {
    layout () {
      var specifiedLayout = this.$route.meta.layout || defaultLayout;
      return specifiedLayout + '-layout';
    }
  },
  mounted () { 
    
  }
};
</script>

<style lang="scss">
#nav {
  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.page-slide-fade-enter-active,
.page-slide-fade-leave-active {
    transition: opacity 0.5s ease;
    position: relative;
    transition: 0.2s ease-in-out;
    top: 0px;
    width: inherit;
}

.page-slide-fade-enter-from {
    opacity: 0;
    width: inherit;
    transform: translateX(50px);
    position: absolute;
    top: 0px;
}

.page-slide-fade-leave-to {
    opacity: 0;
    width: inherit;
    transform: translateX(-50px);
}
</style>
